import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import AppContainer from "./containers/AppContainer";
import NewApp from "./containers/NewApp";
import Bisou from "./containers/Bisou";
import Voyage from "./containers/Voyage";
import Wait from "./containers/Wait";
import BamboozlePrivacy from "./containers/BamboozlePrivacy";

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path={'/bisou'}>
            <Bisou />
          </Route>
          <Route path={'/voyage'}>
            <Voyage />
          </Route>
          <Route path={'/privacy-bamboozle'}>
            <BamboozlePrivacy />
          </Route>
          <Route path='/old'>
            <AppContainer/>
          </Route>
          <Route path='/business' component={() => {
            window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley';
            return null;
          }}/>
          <Route path='/'>
            <NewApp />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}


