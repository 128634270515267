import Link from "@material-ui/core/Link";
import React from "react";
import {useStyles} from "../style/styles";

export default function Copyright() {
  const classes = useStyles();
  return (
    <div className={classes.contact}>
      <p className={classes.secondaryText}>Made with love on Vim with React</p>
      
      <div>
        <p className={classes.secondaryText}>
          {'Contact :  '}
          <a className={classes.secondaryText} href={"mailto:marc@sidohorizon.fr"}>
            marc@sidohorizon.fr
          </a>
        </p>
        <p className={classes.secondaryText}>
          Marc Sidorenko - SIRET 89384068600016 - FRANCE
        </p>
      </div>

      <p className={classes.secondaryText}>
        {'Copyright © SidoHorizon '}
        {new Date().getFullYear()}
      </p>
    </div>
  );
}
