import React from "react";
import {newStyle, useStyles} from "../style/styles";
import Footer from "./Footer";
import Wait from "./Wait";
import Contact from "../secondary/Contact";

export default function NewApp() {
  const classes = newStyle();
  return (
    <div className={classes.waitContainer}>
      <Wait />
      <Footer />
    </div>
  );
}
