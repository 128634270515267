import {makeStyles} from "@material-ui/core/styles";
import './fonts.css';
import BackroundI from '../assets/background.png';

const colorBack = 'rgba(121,189,255,0.3)';
const colorBackSecondary = 'rgba(157,180,213,0.76)';
const colorBlock = 'rgb(221,238,255)';
const colorBackThird = 'rgba(0,40,131,0.76)';
const colorBackFourth = '#333333';


export const newStyle = makeStyles((theme) => ({
  waitContainer: {
    minHeight: '100vh', /* height of the browser viewport */
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${BackroundI})`,
    backgroundSize: 'cover',
  },
  main: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    background: colorBack,
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Nunito-Bold',
    justifyContent: 'space-between',
  },
  navLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Nunito-Bold',
    justifyContent: 'flex-end',
  },
  tagLineDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: "100px",
    marginBottom: "70px",
  },
  projectsDiv: {
    background: colorBackSecondary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  projectsList: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'start',
    minWidth: '50%',
    width: '70%',
    maxWidth: '90%',
    justifyContent: 'center',
  },
  projectBlock: {
    background: colorBlock,
    zIndex: 1,
    borderRadius: 20,
    padding: 15,
    margin: 10,
    minWidth: 250,
    width: '25%',
    maxWidth: '25%',
    height: 150,
  },
  quoteDiv: {
    background: colorBackThird,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '20px',
    paddingBottom: '30px',
  },
  simpleDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '20px',
    paddingBottom: '30px',
  },
  footer: {
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    background: colorBackFourth,
    marginTop: 'auto',
  },
  // Text
  title: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    color: '#1664cb',
    transform: 'rotate(-3deg)',
  },
  titleWhite: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    color: 'white',
  },
  subNav: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito',
    color: '#030f1f',
  },
  tagLineH: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito',
    color: '#1664cb',
  },
  tagLineHSub: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito',
    color: '#1664cb',
  },
  whiteBig: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito-Bold',
    fontWeight: 'bold',
    color: '#ffffff',
  },
  projectName: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito',
    color: '#1664cb',
  },
  p: {
    fontFamily: 'Nunito',
  },
}));


export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  p: {
    fontFamily: 'Nunito',
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    fontFamily: 'Nunito-Bold',
    borderTop: "2px solid #eaeff2 !important",
  },
  core: {
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    /*justifyContent: 'center',
    alignSelf: 'center',*/
    paddingBottom: 150,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito',
    borderTop: "2px solid #eaeff2 !important",
    backgroundImage: `url(${BackroundI})`,
    backgroundSize: 'cover',
    overflow: 'hidden',
  },
  block: {
    background: '#ffffff',
    zIndex: 1,
    borderRadius: 30,
    borderColor: '#00000AA',
    borderTop: '2px solid rgba(0, 50, 120, 0.3)',
    borderLeft: '2px solid rgba(0, 50, 120, 0.3)',
    padding: 10,
    margin: 10,
    minWidth: 250,
    boxShadow: '2px 2px 2px black',
  },
  title: {
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito-Bold',
    fontWeight: 'bold',
    color: '#093978',
  },
  titleBlock: {
    fontFamily: 'Nunito-Bold',
    fontWeight: 'bold',
    color: '#093978',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fefeff',
  },
  text: {
    textAlign: 'center',
    fontFamily: 'Nunito',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperRow: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  paperCustom: {
    padding: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  form_item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    padding: 4,
  },
  form_item_label: {
    padding: 4,
  },
  footer: {
    borderTop: "2px solid #eaeff2 !important",
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    background: '#fAfAfA',
    paddingTop: 0,
    marginTop: 2,
  },
  footerItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  contact: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  contactItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 50,
    marginRight: 50,
    fontFamily: 'Nunito',
  },
  mainText: {
    fontFamily: 'Nunito',
  },
  pWhite: {
    fontSize: 18,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Nunito-Bold',
    fontWeight: 'bold',
    color: '#fafafa'
  },
  secondaryText: {
    fontFamily: 'Nunito',
    color: 'grey',
  }
}));
