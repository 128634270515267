import React from "react";
import {newStyle} from "../style/styles";

export default function Wait() {
  const classes = newStyle();
  return (
    <div>
      <div style={{aligItems: 'center', justifyContent: 'center',textAlign: 'center'}}>
        <br />
        <br />
        <h1 className={classes.titleWhite}>SidoHorizon Technology</h1>
        <br />
        <br />
        <h3 className={classes.titleWhite}>Working on a stealth project...</h3>
        <h3 className={classes.titleWhite}>Travaux sur un projet en furtif...</h3>
      </div>
    </div>
  );
}
